<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th>Contact</th>
          <th>Website</th>
          <th>Title</th>
          <th>Action Name</th>
          <th>Engagement Time</th>
          <th>Action Source</th>
          <th>Date Time</th>
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr v-for="(item, index) in records" :key="index">
          <td class="search-table__id" style="text-align: left;display: flex;">
            <div>
              <img
                :src="profileImage(item.webTrackingAction.contact)"
                alt=""
                class="item-details-card__avatar mt-3 mr-4"
                style="max-width: 20px"
              >
            </div>

            <div>
              <a class="fake-link" @click="goToContactPage(item)">
                <span v-if="item.webTrackingAction.contact.firstName || item.webTrackingAction.contact.lastName">
                  {{ item.webTrackingAction.contact.firstName }} {{ item.webTrackingAction.contact.lastName }}
                </span>
                <span v-else>
                  N/A
                </span>
                <br>
                <small class="text--disabled" style="font-size: 11px;">ID: {{ item.webTrackingAction.contact.id }}</small>
              </a>
            </div>
          </td>
          <td
            class="search-table__creative"
            @click="goToSearchWeb(item.creativeName)"
          >
            {{ item.creativeName }}
          </td>
          <td class="ellipsis">
            <a
              class="download-link"
              target="_blank"
              :href="item.uri"
            >
              <span v-if="item.title">
                {{ item.title }}
              </span>
              <span v-else>
                {{ item.uri }}
              </span>
            </a>
          </td>
          <td>
            {{ getActivityTypeName(item.type) }}
          </td>
          <td>
            {{ item.engagementTime }} seconds
          </td>
          <td>
            {{ item.webTrackingAction.source.name || '-' }}
          </td>
          <td>
            {{ getDateWithTime(item.activityDate) }}
          </td>
        </tr>
        <tr v-if="!records.length">
          <td colspan="8">
            No record found.
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ActionWebTable",
  components: {},
  mixins: [datesMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToContactPage(record) {
      this.$router.push({
        name: "ContactDetails",
        params: {
          id: record.webTrackingAction.contact.id,
        },
      });
    },
    goToSearchWeb(websiteName) {
      this.$router.push({
        name: "WebTrackingScripts",
        query: {
          keyword: websiteName,
        },
      });
    },
    getActivityTypeName(type) {
      switch(type) {
        case "60":
          return 'Webpage Visit';
        case "63":
          return 'Media View';
        case "64":
          return 'Download file';
      }

      return 'Unknown'
    },
    profileImage(contact) {
      if (contact.pictureUrl) {
        return contact.pictureUrl;
      }
      if (contact?.displayContactEmail) {
        return contact.displayContactEmail.gravatarUrl + "?d=mp";
      }

      return 'https://www.sideralsoft.com/wp-content/uploads/2019/03/user-placeholder.png';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.item-details-card__avatar {
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}
</style>

